<template>
  <div class="page">
    <div class="dkzc-box" v-loading="loading">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/index-examSettingPolicy' }">
          卫生法规
        </el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="warp">
      <!-- <div class="video"><video :src="infoData.video"></video></div> -->
      <div v-html="content" class="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // infoData: {},
      loading: false,
      id: 0,
      content: "",
    };
  },

  created() {
    this.id = this.$route.query.id;
    this.getHomeInfo();
  },

  methods: {
    // 卫生法规详情
    async getHomeInfo() {
      this.loading = true;
      let res = await this.$newsApi.getHomeInfo({ id: this.id });
      this.loading = false;
      // this.infoData = res.data;
      this.content =  res.data.content.replace(/<a/g, "<a target='_blank'");
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  background: #f2f2f2;
  > .dkzc-box {
    // display: flex;
    // flex-direction: row;
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-bottom: 42px;
    }
  }
  .warp {
    width: 1200px;
    margin: 0 auto;
    padding: 30px;
    background: #fff;
    box-sizing: border-box;
  }
}
</style>
